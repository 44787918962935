// src/Home.tsx

import React, { useEffect } from 'react';
import { isIOS, isAndroid } from 'react-device-detect';

const Home: React.FC = () => {
  useEffect(() => {
    const redirectTo = (url: string) => {
      window.location.replace(url);
    };

    if (isIOS) {
      redirectTo('https://apps.apple.com/fr/app/annecy-m%C3%A9t%C3%A9o/id6714475583');
    } else if (isAndroid) {
      redirectTo('https://play.google.com/store/apps/details?id=com.annecymeteoappv3&hl=fr');
    } else {
      redirectTo('https://bento.me/annecymeteo');
    }
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px', color:'#EFF5FF' }}>
      <p>Ouverture du Play Store/App Store...</p>
    </div>
  );
};

export default Home;